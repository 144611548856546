<template>
  <div v-if="prices">
    <!-- @TODO: Add again once we have the information about the quantity and unit. -->
    <!-- <div class="uppercase font-medium text-xs">1 Stück</div> -->

    <div>
      <div
        v-if="priceLarge"
        :class="{
          'text-md md:text-xl lg:text-2xl uppercase !leading-none lg:inline-block':
            !isFreeLargePrice,
          'text-md md:text-lg lg:text-lg !leading-none lg:inline-block':
            isFreeLargePrice,
        }"
      >
        {{ priceLarge }}
      </div>

      <div
        v-if="priceSmall"
        class="text-sm lg:ml-10 leading-none lg:inline-block"
      >
        {{ priceSmall }}
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import {
  FieldItemTypeCommercePriceFragment,
  FieldTypeRoleBasedPriceFragment,
} from '#graphql-operations'
import { useDrupalUser } from '~/stores/drupalUser'
import { useGlobalConfig, type PriceLogic } from '~/stores/globalConfig'

const drupalUser = useDrupalUser()
const globalConfig = useGlobalConfig()
const { $texts } = useNuxtApp()

const props = defineProps<{
  // The full price (without any discounts) formatted.
  fullPriceFormatted?: string

  // The full price (without discount) number.
  fullPriceNumber?: string

  // The price for the current user.
  currentUserPrice?: FieldItemTypeCommercePriceFragment['currentUserPrice']

  // The roles required to buy the product.
  roles?: string[]

  roleBasedPrices?: FieldTypeRoleBasedPriceFragment[]
}>()

// Large and small refer to the price display size and not the price value.
type Prices = PriceLogic & {
  largeNumber?: number | null
  smallNumber?: number | null
}

const requiredRole = computed(() => props.roles?.[0] || '')

// This may theoretically vary from the current user price as the current user
// price may include discounts that are not role-based. The role based price
// does not include the anonymous or authenticated roles, meaning that we assume
// that any role based price is the discounted price. Theoretically though
// the current user price may be different from the role based price and could
// actually be higher than the other price. Having this working depends on
// commerce discounts being only role based and the user with a role having
// an equal or discounted price compared to anonymous users..
const discountedCurrentUsersRoleBasedPriceFormatted = computed(() => {
  return (
    props.roleBasedPrices?.find((user) => user.role === requiredRole.value)
      ?.formatted || ''
  )
})

// This is the same as above, but just the price number and not the formatted
// value.
const discountedCurrentUserRoleBasedPriceNumber = computed(() => {
  return (
    props.roleBasedPrices?.find((user) => user.role === requiredRole.value)
      ?.number || ''
  )
})

const safeParseNumber = (value: string): number | null => {
  const parsedValue = parseFloat(value)
  return isNaN(parsedValue) ? null : parsedValue
}

const prices = computed<Prices | undefined>(() => {
  debugger;
  if (!props.currentUserPrice?.formatted) {
    return
  }

  // Here there is a price display rule defined that matches the user role
  // and the product required role. Note: there is no guarantee that the
  // product paywall configuration matches the price display rule.
  if (requiredRole) {
    const userRole = drupalUser.primaryRole || 'anonymous'
    const key = userRole + '__' + requiredRole.value
    if (globalConfig.priceLogic[key]) {
      // We also map the number to the price display rule at this point, so
      // that we later don't need to parse strings to check string equality
      // or if the price is free.
      const price = globalConfig.priceLogic[key]
      let largeNumber: number | null = null
      let smallNumber: number | null = null
      if (price.large.includes('@price')) {
        largeNumber = safeParseNumber(props.fullPriceNumber)
      } else if (price.large.includes('@discounted')) {
        largeNumber = safeParseNumber(
          discountedCurrentUserRoleBasedPriceNumber.value,
        )
      }
      if (price.small?.includes('@price')) {
        smallNumber = safeParseNumber(props.fullPriceNumber)
      } else if (price.small?.includes('@discounted')) {
        smallNumber = safeParseNumber(
          discountedCurrentUserRoleBasedPriceNumber.value,
        )
      }
      return {
        ...price,
        smallNumber,
        largeNumber,
      }
      // Example:
      // {
      //   large: '@price',
      //   largeNumber: props.fullPriceNumber,
      //   small: '@discounted for affiliated pharmacies',
      //   smallNumber: discountedCurrentUserRoleBasedPriceNumber.value,
      // }
      //   or
      // {
      //   large: '@discounted',
      //   largeNumber: discountedCurrentUserRoleBasedPriceNumber.value,
      //   small: '@price for non-affiliated pharmacies',
      //   smallNumber: props.fullPriceNumber,
      // }
    }
  }

  return {
    // If no role based price is defined for the current combination of
    // user role and product required role we return only the large price
    // as the current calculated user price.
    large: props.currentUserPrice?.formatted,
    largeNumber: safeParseNumber(props.currentUserPrice?.number),
  }
})

function isFreePrice(price: number | null) {
  // Check if the price is null or undefined.
  if (price === null || price === undefined) {
    return true
  }
  // Define a small epsilon value to check for near-zero values.
  const epsilon = 0.00001
  // Check if the price is 0 or NaN.
  return price === 0 || Math.abs(price) < epsilon
}

function pricesAreEqual(price1: number | null, price2: number | null) {
  debugger
  // If both prices are null, they are considered equal.
  if (price1 === null && price2 === null) {
    return true
  }
  // If one of the prices is null, they are not equal.
  if (price1 === null || price2 === null) {
    return false
  }
  // Compare the two prices with a small epsilon value to account for floating-point precision issues.
  const epsilon = 0.00001
  return Math.abs(price1 - price2) < epsilon
}

const priceLarge = computed(() => {
  debugger
  const largePriceNumber = prices.value.largeNumber
  const largePriceFormatted = prices.value.large
  return isFreePrice(largePriceNumber)
    ? renderFreePrice(largePriceFormatted)
    : renderPrice(largePriceFormatted)
})

const priceSmall = computed(() => {
  debugger
  const smallPriceNumber = prices.value?.smallNumber
  const smallPriceFormatted = prices.value?.small
  const largePriceNumber = prices.value?.largeNumber
  if (!smallPriceFormatted) {
    return
  }
  // We don't show the small price if both are the same price.
  // Includes checks the values are the same. The small price is not yet
  // converted to 'Kostenlos' and so we need to check if both are free.
  if (pricesAreEqual(largePriceNumber, smallPriceNumber)) {
    return
  }
  return isFreePrice(smallPriceNumber)
    ? renderFreePrice(smallPriceFormatted)
    : renderPrice(smallPriceFormatted)
})

const isFreeLargePrice = computed(() => {
  const largePriceNumber = prices.value?.largeNumber
  return isFreePrice(largePriceNumber)
})

function renderPrice(v = ''): string {
  return v
    .replace('@price', props.fullPriceFormatted || '')
    .replace(
      '@discounted',
      discountedCurrentUsersRoleBasedPriceFormatted.value || '',
    )
}

function renderFreePrice(v = ''): string {
  const freeDisplay = $texts('free', 'Kostenlos')
  return v.replace('@price', freeDisplay).replace('@discounted', freeDisplay)
}
</script>
